@import "../../variables";
#company-intro {
    .container {
        margin:0; padding:0;
    }
    .row {
        margin:0; padding:0;
    }
    @media(max-width: $breakpoint-desktop) {
        padding:0px 154px;
    }
    @media(max-width: $breakpoint-tablet) {
        padding-left:20px;
        padding-right:20px;
    }
    @media(max-width: $breakpoint-smartphone) {
        padding-left:20px;
        padding-right:20px;
    }
    max-width:100%;
    min-height:100vh;
    background-image: url('../../images/company_intro.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    @media(min-height: 650px) {
        min-height: 100vh;
    }
    @media(min-height: 730px) {
        min-height:100vh;
    }
    @media(min-height: 800px) {
        min-height:100vh;
    }

    .menu-logo {
        padding-top:66px;
    }

    .company-message {
        font-family: 'Montserrat';
        color:white;
        position: absolute;
        top:50%;
        transform:translateY(-50%);
        h2 {
            font-weight: 600;
            line-height: 65px;
            margin-bottom: 40px;
            @media(max-width: $breakpoint-desktop) {
                font-size: 80px;
            }
            @media(max-width: $breakpoint-tablet) {
                font-size: 80px;
            }
            @media(max-width: $breakpoint-smartphone) {
                line-height: 40px;
                font-size: 40px;
                letter-spacing: 1px;
            }
        }
        p{
            font-size:20px;
            padding:0;
            line-height: 30px;
            @media(max-width: $breakpoint-smartphone) {
                font-size:17px;
                line-height: 25px;
            }
            @media(min-height: 1100px) {
                font-size:25px;
                line-height: 40px;
            }
        }
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            position:absolute;
            top:50%;
            transform:translateY(-50%);
       }
    }

    .fadeIn {
        transition: 0.7s ease-in-out;
        opacity: 0;
        transform: translateY(-40%);
    }

    .fadeIn.enter {
        transition: 0.7s ease-in-out;
        opacity: 1;
        transform:translateY(-50%);
    }
    @media (max-width: 500px){
        .fadeIn{
            opacity: 1;
            transform:translateY(-50%)
        }
    }
}
