@import "../../variables.scss";

.fadeIn {
  transition: 0.7s ease-in-out;
  opacity: 0;
}
.fadeIn.enter {
  transition: 0.7s ease-in-out;
  opacity: 1;
}
@media (max-width: 600px) {
  .fadeIn {
    opacity: 1;
  }
}
.contact-form {
  flex-direction: row;
  justify-content: center;
  .formContact {
    .name-email-boxes {
      display: flex;
      @media (max-width: $breakpoint-smartphone) {
        display: block;
      }
      div {
        width: 100%;
      }
      .name-box {
        margin-right: 10px;
        @media (max-width: $breakpoint-smartphone) {
          margin-right: 0;
        }
      }
      .email-box {
        margin-left: 10px;
        @media (max-width: $breakpoint-smartphone) {
          margin-left: 0;
        }
      }
    }
    @media (max-width: $breakpoint-desktop) {
      min-width: 828px;
    }

    @media (max-width: $breakpoint-tablet) {
      min-width: 510px;
    }

    @media (max-width: $breakpoint-smartphone) {
      min-width: 91%;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      min-width: 80%;
    }

    // Safari
    @supports (overflow: -webkit-marquee) and (justify-content: inherit) {
      min-width: 80%;
    }
  }
  form {
    color: white;
    @media (max-width: $breakpoint-desktop) {
      width: 100%;
    }
    @media (max-width: $breakpoint-tablet) {
      width: 80%;
    }
    @media (max-width: $breakpoint-smartphone) {
      width: 91%;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      min-width: 80%;
    }

    // Safari
    @supports (overflow: -webkit-marquee) and (justify-content: inherit) {
      min-width: 80%;
    }

    h3 {
      line-height: 60px;
    }

    .input-roles {
      color: white;
      @media (max-width: $breakpoint-desktop) {
        font-size: 18px;
      }
      @media (max-width: $breakpoint-tablet) {
        font-size: 13px;
        width: 40%;
      }
      @media (max-width: $breakpoint-smartphone) {
        font-size: 12px;
        width: 50%;
      }
      font-weight: bold;
      text-align: center;
      background-color: transparent;
      outline: 0;
      border: 0;
      border-bottom: 2px solid white;
    }
    input::-webkit-calendar-picker-indicator {
      opacity: 100;
      &:hover {
        background-color: transparent;
      }
    }
    textarea {
      max-height: 300px;
    }
    textarea,
    .input-name {
      width: 100%;
      @media (max-width: $breakpoint-desktop) {
        font-size: 18px;
      }
      @media (max-width: $breakpoint-tablet) {
        font-size: 13px;
      }
      @media (max-width: $breakpoint-smartphone) {
        font-size: 18px;
        width: 100% !important;
      }
      @media (min-height: $breakpoint-vertical-desktop) {
        font-size: 16px;
      }
      @media (min-height: 1100px) {
        font-size: 25px;
      }
      @media (max-width: 375px) {
        font-size: 15px;
      }
      @media (max-width: 330px) {
        font-size: 13px;
      }

      font-weight: lighter;
      color: white;
      line-height: 4ch;
      background-color: transparent;
      border: 0;
      background-image: linear-gradient(
        transparent,
        transparent calc(4ch - 1px),
        white 0px
      );
      background-size: 100% 4ch;
      &:focus {
        outline: 0;
        border: 0;
      }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        background-image: none;
        border-bottom: 1px solid white;
      }
    }

    .input-alert-true {
      color: #e04646;
      width: 100% !important;
      background-image: linear-gradient(
        #ff050500,
        #ff000000 calc(4ch - 1px),
        #e04646 0px
      );
      &::placeholder {
        color: #e04646;
      }
      @media (min-height: $breakpoint-vertical-desktop) {
        font-size: 16px;
      }
    }

    .invalid-field {
      color: #e04646;
      @media (max-width: $breakpoint-desktop) {
        font-size: 18px;
      }
      @media (max-width: $breakpoint-tablet) {
        font-size: 13px;
      }
      @media (max-width: $breakpoint-smartphone) {
        font-size: 12px;
      }
    }

    [type="file"] {
      display: none;
      & + label {
        text-align: center;
        background: white;
        color: black;
        cursor: pointer;
        border-radius: 25px;
        @media (max-width: $breakpoint-desktop) {
          padding: 15px 20px 15px 20px;
          font-size: 18px;
        }
        @media (max-width: $breakpoint-tablet) {
          padding: 10px 20px 10px 20px;
          font-size: 13px;
        }
        @media (max-width: $breakpoint-smartphone) {
          padding: 10px 20px 10px 20px;
          font-size: 12px;
        }
        @media (max-width: 330px) {
          margin-top: 1rem !important;
        }
      }
    }
    .form-submit {
      background-color: transparent;
      color: white;
      @media (max-width: $breakpoint-desktop) {
        padding: 15px 50px 15px 50px;
        font-size: 18px;
      }
      @media (max-width: $breakpoint-tablet) {
        padding: 10px 50px 10px 50px;
        font-size: 17px;
      }
      @media (max-width: $breakpoint-smartphone) {
        padding: 10px 50px 10px 50px;
        font-size: 17px;
      }
      @media (min-height: $breakpoint-vertical-desktop) {
        font-size: 20px;
      }
      @media (max-width: 330px) {
        font-size: 15px;
        padding: 5px 25px 5px 25px;
        margin-top: 1rem !important;
      }
      border: 1px solid white;
      border-radius: 40px;
      transition: all 0.5s;
      &:hover {
        background-color: white;
        border: 1px solid black;
        color: black;
        transition: all 0.5s;
      }
      &:focus {
        outline: 0;
      }
    }
    input::placeholder,
    textarea::placeholder {
      color: rgb(218, 218, 218);
    }
    .form-error {
      color: #e04646;
    }

    input {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        background-image: none;
        min-height: 40px;
      }
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: 50%;
      margin: auto;
    }
  }
}
