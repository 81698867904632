@import "../../variables.scss";
.list-view {
    font-family: "Montserrat";
    color: white;
    p {
        font-size: 20px;
        font-weight: 100;
        line-height: 30px;
        margin: 50px 0px;
        padding: 0;
        @media (max-width: $breakpoint-tablet) {
            line-height: 23px;
            font-size: 17px;
        }
        @media (max-width: $breakpoint-smartphone) {
            line-height: 23px;
            font-size: 15px;
        }
        @media (min-height: $breakpoint-vertical-desktop) {
            font-size: 20px;
        }
    }
    ul {
        padding: 0;
        margin-bottom: 50px;
        li {
            font-size: 20px;
            font-weight: 100;
            list-style-type: none;
            position: relative;
            padding: 0 0 0 10px;
            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 39%;
                width: 5px;
                height: 5px;
                background-color: white;
                border-radius: 50%;
                -moz-border-radius: 50%;
                -webkit-border-radius: 50%;
            }
            @media (max-width: $breakpoint-tablet) {
                line-height: 26px;
                font-size: 17px;
            }
            @media (max-width: $breakpoint-smartphone) {
                line-height: 23px;
                font-size: 15px;
            }
            @media (min-height: $breakpoint-vertical-desktop) {
                font-size: 17px;
            }
        }
    }
}
