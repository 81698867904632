@import "../../variables.scss";
.company-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width:100%;
    min-height:100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    padding-bottom:0px;
    @media(min-height: 650px) {
        .list-view p{
            font-size: 20px;
            line-height: 30px;
        }
        .list-view ul li{
            font-size: 20px;
            line-height: 30px;
        }
        
        min-height: 100vh;
    }
    @media(min-height: 730px) {
        min-height:100vh;
    }
    @media(min-height: 1000px) {
        .list-view p{
            font-size: 30px;
        }
        .list-view ul li{
            font-size: 30px;
        }
    }
    @media(min-height: 1100px) {
        .list-view p{
            font-size: 25px;
            line-height: 40px;
        }
        .list-view ul li{
            font-size: 25px;
            line-height: 40px;
        }
    }
    @media (max-width: 375px) and (max-height: 790px){
        .list-view p{
            font-size: 15px;
            line-height: 25px;
        }
        .list-view ul li{
            font-size: 15px;
            line-height: 25px;
        }
    }
    @media (max-width: 330px) {
        .list-view p{
            font-size: 13px;
            line-height: 25px;
        }
        .list-view ul li{
            font-size: 13px;
            line-height: 25px;
        }
    }


    .content-wrapper {
        @media(max-width: $breakpoint-desktop) {
            padding:0px 154px;
        }
        @media(max-width: $breakpoint-tablet) {
            padding-left:0px;
            padding-right:20px;
        }
        @media(max-width: $breakpoint-smartphone) {
            padding-left:20px;
            padding-right:20px;
        }
        .company-message {
            .section-logo {
                padding-top:0px;
                img {
                    max-width:200px;
                    @media(max-width: $breakpoint-smartphone) {
                        max-width:120px;
                    }
                    @media(min-height: 1100px){
                        max-width:200px;
                    }
                }
                h2 {
                    color:white;
                    font-size:23px;
                    padding-top:10px;
                    @media(max-width: $breakpoint-smartphone) {
                        font-size:20px;
                    }
                }
            }
            .fadeIn {
                transition: 0.7s ease-in-out;
                opacity: 0;
                transform: translateY(20px);
                
              }
            .fadeIn.enter {
                transition: 0.7s ease-in-out;
                opacity: 1;
                transform: translateY(0px);
            }
            @media (max-width: 600px){
                .fadeIn{
                    opacity: 1;
                    transform: translateY(0px);
                }
            }

            @media (max-width: $breakpoint-tablet) {
                padding: 0 0 0 50px;
            }
        }
        .visit-site {
            outline:0;
            background-color:transparent;
            color:white;
            border:1px solid white;
            border-radius:30.5px;
            padding:15px 40px;
            @media(max-width: $breakpoint-tablet) {
                font-size:17px;
                padding:12px 25px;
            }
            @media(max-width: $breakpoint-smartphone) {
                font-size:17px;
                padding:10px 25px;
            }
            @media(min-height: $breakpoint-vertical-desktop) {
                font-size:20px;
                padding:12px 35px;
            }
            @media(min-height: 1100px) {
                font-size: 25px;
                padding: 15px 30px;
            }
            transition: all 0.5s;
            &:hover {
                background-color:white;
                border:1px solid black;
                transition: all 0.5s;
                color:black;
                text-decoration: none;
                a {
                    color:black;
                    transition: all 0.5s;
                }
            }
            a {
                color:white;
                transition: all 0.5s;
            }
        }
    }
}

.notSelected {
    transition: 1s ease-in-out;
    opacity: 0.4;
}

.selected {
    transition: 1s ease-in-out;
    opacity: 1;
}

.left {
    transition: 2s ease-in-out;
    transform: translateX(-18px);
}

.normal {
    transition: 2s ease-in-out;
    transform: translateX(0px);
}


.section-image {
    width: 110%;
}
