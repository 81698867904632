@import "../../variables.scss";

.burger-menu {
    ul {
        width:55px;
        height:30px;
        @media(max-width: $breakpoint-smartphone) {
            width:35px;
            height:20px;
        }
        margin:0;
        padding:0;
        position:fixed;
        top:30px;
        right:30px;
        z-index:19;
        li {
            margin:0;
            padding:0;
            list-style-type: none;
            position:absolute;
            height:2px;
            background-color:#cecece;
            &:first-child {
                top:0;
                right:0;
                width:100%;
            }
            &:nth-child(2) {
                top:50%;
                right:0;
                transform:translateY(-50%);
                width:80%;
            }
            &:nth-child(3) {
                bottom:0;
                right:0;
                width:60%;
            }
            transition: all 0.5s;
        }
        &:hover {
            li {
                width:100%;
                transition: all 0.5s;
            }
        }
    }
}

.side-menu {
    z-index:20;
    .close-menu img {
        max-width:30px;
        margin-bottom:40px;
        cursor:pointer;
        position:absolute;
        top:30px;
        right:30px;
    }
    ul {
        width:100%;
        height:100%;
        position:fixed;
        z-index:20;
        text-align:center;
        padding:40px 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color:rgba(0, 0, 0, 0.85);

        li {
            list-style-type: none;
            font-size:32px;
            @media(max-width: $breakpoint-tablet) {
                font-size:30px;
            }
            @media(max-width: $breakpoint-smartphone) {
                font-size:20px;
                line-height:35px;
            }
            @media(min-height: 1100px) {
                font-size:35px;
                line-height:55px;
            }
            font-weight:600;
            line-height:55px;
            a {
                color:white;
                letter-spacing: 1px;
            }
        }
        transition:all 0.7s ease-out;
    }
}

.closed {
    top:calc(100vh - 200vh);
}

.open {
    top:0 !important;
}

.open-menu {
    ul {
        right:0;
    }
}