@import "variables.scss";
@import "./components/Layout/Layout.scss";
html {
    scroll-behavior: smooth;
}
body {
    background-color: black;
    font-family: "Montserrat";
    #scrollmouse {
        position: fixed;
        bottom: 0;
        left: calc(50% - 25px);
        transform: translateX(-50%);
        z-index: 20;
        margin-bottom: 20px;
        opacity: 1;
        transition: all 1s;
        img {
            max-height: 50px;
        }
        img {
            @media (max-width: $breakpoint-tablet) {
                max-height: 45px;
            }
            @media (max-width: $breakpoint-smartphone) {
                max-height: 30px;
            }
        }
        @keyframes NAME-YOUR-ANIMATION {
            0% {
                transform: translateY(-8px);
            }
            10% {
                transform: translateY(0px);
            }
            80% {
                transform: translateY(0px);
            }
            100% {
                transform: translateY(-8px);
            }
        }
        animation: NAME-YOUR-ANIMATION 3s infinite; /* IE 10+, Fx 29+ */
        animation-timing-function: ease-in-out;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
        }
        &:hover {
            cursor: pointer;
        }
    }
}

.App {
    overflow-x: clip;
}

.recruit-section,
.contacts-section {
    font-family: "Montserrat";
    margin-top: 60px;
    @media (min-height: 1100px) {
        margin-top: 0px;
    }
    .recruit-wrapper,
    .contacts-wrapper {
        max-width: 100%;
        min-height: 100vh;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;
        padding: 0;
        .content-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            place-content: center;
            h2 {
                @media (max-width: $breakpoint-smartphone) {
                    width: 100%;
                }
                @media (min-height: $breakpoint-vertical-desktop) {
                    width: 70%;
                }
            }
        }
        h2 {
            color: White;
            font-weight: 600;
            @media (max-width: $breakpoint-desktop) {
                font-size: 48px;
            }
            @media (max-width: $breakpoint-tablet) {
                font-size: 48px;
            }
            @media (max-width: $breakpoint-smartphone) {
                font-size: 25px;
            }
        }
        h3 {
            color: white;
            font-weight: lighter;
            margin-bottom: 50px;
            @media (max-width: $breakpoint-desktop) {
                font-size: 23px;
            }
            @media (max-width: $breakpoint-tablet) {
                font-size: 15px;
            }
            @media (max-width: $breakpoint-smartphone) {
                font-size: 20px;
            }
            @media (max-width: 375px){
                font-size: 15px;
            }
            @media (max-width: 330px) {
                font-size: 13px;
            }
            @media (min-height: $breakpoint-vertical-desktop) {
                font-size: 40px;
            }
            @media (min-height: 1100px) {
                font-size: 25px;
            }
        }
        z-index: 1;
        .top-fader {
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 245px;
            background-image: url(images/fader_bg.png);
            opacity: 0.4;
            filter: alpha(opacity=50);
        }
        .bot-fader {
            z-index: -1;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 245px;
            background-image: url(images/fader_bottom_bg.png);
            opacity: 0.4;
            filter: alpha(opacity=50);
        }
        .footer-contacts {
            color: white;
            background-color: #000;
            height: 20vh;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: $breakpoint-smartphone) {
                height: 20%;
            }

            .footer-logo {
                text-align: center;

                @media (max-width: $breakpoint-smartphone) {
                    text-align: left;
                }
                @media (max-width: $breakpoint-tablet) {
                    text-align: left;
                }

                img {
                    @media (max-width: $breakpoint-tablet) {
                        padding-left: 15px;
                        padding-bottom: 15px;
                    }
                    @media (max-width: $breakpoint-smartphone) {
                        padding-left: 20px;
                        padding-bottom: 20px;
                    }
                }
            }
            h4 {
                font-weight: 600;
                @media (max-width: $breakpoint-desktop) {
                    font-size: 18px;
                }
                @media (max-width: $breakpoint-tablet) {
                    font-size: 15px;
                }
                @media (max-width: $breakpoint-smartphone) {
                    font-size: 18px;
                    padding-left: 5px;
                }
            }
            p {
                font-weight: 400;
                @media (max-width: $breakpoint-desktop) {
                    font-size: 14px;
                }
                @media (max-width: $breakpoint-tablet) {
                    font-size: 12px;
                }
                @media (max-width: $breakpoint-smartphone) {
                    font-size: 12px;
                    padding-left: 5px;
                }
            }
        }
    }
}

#back-to-top {
    opacity: 0.5;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 22;
    @media (max-width: $breakpoint-smartphone) {
        text-align: center;
        padding-right: 0px;
    }
    img {
        max-width: 30px;
    }
}

.svg-scroll {
    fill: white;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.15));
}

/*@media(min-height: 650px) {
    .min-vh-100{
        min-height: 100vh !important;
    }
}
@media(min-height: 730px) {
    .min-vh-100{
        min-height:100vh !important;
    }
}
@media(min-height: 800px) {
    .min-vh-100{
        min-height:100vh !important;
    }
}*/