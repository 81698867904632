@import "../../variables.scss";
#companies {
  max-width:100%;
  min-height:70vh;
  position: relative;
  background-color:black;

  .owl-bgimage, .evk-bgimage, .bnp-bgimage  {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity:0.5;
    -webkit-opacity:0.5;

    /* IE 5-7-8 */
    filter: alpha(opacity=50);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";

    transition: all 1s;
    cursor:pointer;

  }
  #companies-wrapper {
    flex-direction:row;
    .company-strip {

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      height:500px;
    }

      &:hover {
          .owl-bgimage, .evk-bgimage, .bnp-bgimage {
              opacity:1;
              -webkit-opacity:1;
              filter: alpha(opacity=100);
          }
          .companies-title {
              h2, p {
                  cursor:pointer;
              }
          }
      }
    }
  }

  .owl-bgimage {background-image:url('../../images/owl_wine.png');}
  .evk-bgimage {background-image:url('../../images/evk_duet.png');}
  .bnp-bgimage {background-image:url('../../images/bnp_slot_cor.png');}
  .companies-title {
    font-family: 'Montserrat';
    color:white;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
      h2 {
        font-weight:600;
        @media(max-width: $breakpoint-desktop) {
            font-size:30px;
        }
        @media(max-width: $breakpoint-tablet) {
            font-size:25px;
        }
        @media(max-width: $breakpoint-smartphone) {
            line-height: 25px;
            font-size: 25px;
        }
      }
      p{
        font-weight:400;
        @media(max-width: $breakpoint-desktop) {
            font-size:20px;
        }
        @media(max-width: $breakpoint-tablet) {
            font-size:20px;
        }
        @media(max-width: $breakpoint-smartphone) {
            line-height: 20px;
            font-size: 15px;
        }
        @media(min-height: 1100px) {
          font-size: 25px;
      }
      }
  }
}
