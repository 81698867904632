@import "../../variables.scss";
.brandssection {
    height: 100vh;
    background-color: white;
    clip-path: polygon(50% 0%, 100% 0, 100% 84%, 68% 96%, 50% 100%, 31% 96%, 0 83%, 0 0);
    @media (max-width: $breakpoint-smartphone) {
        clip-path: none;
    }
    h2 {
        font-weight: 600;
        @media (max-width: $breakpoint-desktop) {
            font-size: 48px;
        }
        @media (max-width: $breakpoint-tablet) {
            font-size: 48px;
        }
        @media (max-width: $breakpoint-smartphone) {
            font-size: 25px;
        }
    }
    p {
        font-size: 23px;
        margin-bottom: 35px;
        @media (max-width: $breakpoint-tablet) {
            font-size: 17px;
        }
        @media (max-width: $breakpoint-smartphone) {
            font-size: 17px;
        }
    }

    .grid-list {
        margin-bottom: 150px;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            width: 100%;
            margin-bottom: 50px;
        }
        .brand-logo img {
            opacity: 0.3;
        }
    }
    .mx-auto {
        margin-top: 50px;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        position: relative;
    }
    .fadeIn {
        transition: opacity 1s ease-in-out;
        opacity: 0;
    }
    .fadeIn.enter {
        transition: opacity 1s ease-in-out;
        opacity: 1;
    }
    @media (max-width: 600px){
        .fadeIn{
            opacity: 1;
        }
        .mx-auto {
            margin-top: 25px;
        }
    }
    @media (max-width: 600px){
        .fadeIn{
            opacity: 1;
        }
        .mx-auto {
            margin-top: 25px;
        }
    }
}
